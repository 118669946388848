<template>
    <div class="post mx-auto">
        <p class="mb-0">{{ t(`enterTitle`) }}:</p>
        <h4><input type="text" v-model="postInfo.title" class="w-100"></h4>
        {{ t('tags') }}: <Select2 v-model="tags" :options="tagsList.map(tag => tag['name_' + currLang])" :settings="{ multiple: true }" class="w-100 select"/>
        
        <p class="mt-3 d-flex align-items-center justify-content-end">
            <span>{{ t('image') }}</span> &nbsp; &nbsp; 
            <b-form-checkbox switch size="lg" v-model="videoOnThumb" class="mt-n1"></b-form-checkbox>
            <span>{{ t('video') }}</span>
        </p>
        <file-drop v-if="!videoOnThumb" class="my-2" @imageChange="imageChange"/>
        <div v-else>
            <h5>{{ t('youtubeOrMover') }}</h5>
            <div class="d-flex align-items-center">
                <span class="mr-3 ml-2"><b-icon-youtube/></span>
                <b-form-input
                    v-model="videoUrl"
                    type="text"
                    :placeholder="t('videoUrl')"
                />
            </div>
            <b-embed
                v-if="embed"
                class="mt-2"
                type="iframe"
                aspect="16by9"
                :src="embed"
                allowfullscreen
            ></b-embed>

        </div>
        <p class="mb-0 mt-3">{{ t(`enterDescription`) }}:</p>
        <h5><input type="text" v-model="postInfo.description" class="w-100 mb-3"></h5>

        <vue-editor
            v-model="postInfo.content" 
            use-custom-image-handler 
            @image-added="handleImageAdded" 
            @image-removed="removeImage"
        />
        <div class="bottom">
            <div class="profile-pic" :style="author.avatar ? {'background-image': `url(${author.avatar})`} : {}" />
            <p>{{ author.name }}</p>
            <p class="time">{{ parseTime(Date.now()) }}</p>
            <button class="main submit-btn" @click.prevent="submit">{{ t('publicate') }}</button>
        </div>
    </div>
</template>

<script>
import FileDrop from '../../components/FileDrop.vue';
import parseTime from '../../utils/parseTime';
import { VueEditor } from "vue2-editor";
import Select2 from 'v-select2-component';

export default {
    name: 'CreatePost',
    components: { 
        FileDrop,
        VueEditor,
        Select2
    },
    async beforeCreate() {
        const tagsResp = await this.axios.get('category')
        this.tagsList = tagsResp?.data?.data || []
    },
    data() {
        return {
            liked: false,
            postInfo : {},
            tagsList: [],
            tags: [],
            videoUrl: '',
            videoOnThumb: false,
        }
    },
    methods: {
        removeImage(url) {
            this.axios.post('images/remove', {image: url})
        },
        handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
            var formData = new FormData();
            formData.append("image", file);

            this.axios({
                url: "/images",
                method: "POST",
                data: formData
            })
            .then(result => {
                let url = result.data.url; // Get url from response
                Editor.insertEmbed(cursorLocation, "image", url);
                resetUploader();
            })
            .catch(err => {
                err = err.response?.data?.message || err.message
                if(err.includes('The thumbnail failed to upload.')) {
                    this.error(this.t('imageTooBig'))
                } else
                    this.error(err);
            });
        },
        parseTime: (time) => parseTime(time),
        imageChange(file) {
            this.postInfo.thumbnail = file
        },
        getTagsIds() {
            let temp = []
            this.tagsList.forEach(tag => {
                if(this.tags.includes(tag['name_' + this.currLang])) {
                    temp.push(tag.id)
                }
            })
            return temp
        },
        async submit() {
            if(!this.postInfo.title) {
                this.error(this.t('titleNeeded'))
                return;
            } else if(!this.tags.length) {
                this.error(this.t('tagsNeeded'))
                return;
            } else if(this.postInfo.content == 'undefined' || !this.postInfo.content) {
                this.error(this.t('fillWithText'))
                return;
            } else if(!this.postInfo.description) {
                this.error(this.t('descriptionNeeded'))
                return;
            } else if(this.videoOnThumb && !this.embed) {
                this.error(this.t('videoWrong'))
                return;
            } else if(!this.videoOnThumb && !this.postInfo.thumbnail) {
                this.error(this.t('imageNeeded'))
                return;
            } else if(!this.videoOnThumb && (this.postInfo.thumbnail.size / 1024 / 1024) > 4) {
                this.error(this.t(`imageTooBig`))
                return;
            }

            const formData = new FormData();
            this.getTagsIds().forEach(tag => {
                formData.append('category[]', tag)
            })
            formData.append('title', this.postInfo.title)
            formData.append('content', this.postInfo.content)
            formData.append('description', this.postInfo.description)
            if(!this.videoOnThumb)
                formData.append('thumbnail', this.postInfo.thumbnail)
            else
                formData.append('preview', this.embed)

            try {
                if(!this.videoOnThumb)
                    await this.axios.post('news', formData)
                else
                    await this.axios.post('news/store/video-preview', formData)

                this.alert(this.t('postPremoderating'))
                this.$router.push('/personal?tab=moderating')
                this.postInfo = {}
                this.tagsList = []
                this.tags = []
                this.videoUrl = ''
                this.videoOnThumb = false
            } catch (e) {
                const data = e.response?.data?.message || []
                if(data.includes('The category field is required.')) {
                    this.error(this.t('tagsNeeded'))
                } else if(data.includes("The thumbnail must be a file of type: jpeg, jpg, png.")) {
                    this.error(this.t('imageNeeded'))
                } else if(data.includes("The category field is required.")) {
                    this.error(this.t('tagsNeeded'))
                } else if(data.includes('The thumbnail failed to upload.')) {
                    this.error(this.t('imageTooBig'))
                }
            }
        }
    },
    computed: {
        author() {  
            if(this) {
                this.$forceUpdate();
                if(this.$store.state.userHasCompany) 
                    return this.$store.state.usersCompany
                else
                    return this.$store.state.userInfo
            } else return {}
        },
        embed() {
            let video = this.videoUrl
            if(video) {
                if(video.includes('<iframe')) {
                    video = video.split('src="')[1].split('"')[0]
                }
                if(video.includes('mover.uz/')) {
                    const id = video.split('mover.uz/watch/')[1].split('?')[0]
                    return 'https://mover.uz/video/embed/' + id
                } else if(video.includes('youtu.be/')) {
                    const id = video.split('youtu.be/')[1].split('?')[0]
                    return `https://www.youtube.com/embed/` + id
                } else if(video.includes('youtube.com')) {
                    if(video.includes('/embed/')) {
                        return video
                    }
                    const id = video.split('watch?v=')[1].split('&')[0]
                    return `https://www.youtube.com/embed/` + id
                }
                return ''
            }
            else return ''
        }
    },
}
</script>

<style scoped lang="scss">
    input, .quillWrapper {
        border-radius: 3px !important;
        padding-left: 0 !important;
        border: 1px solid var(--itpark-semi);
    }
    input:hover, .quillWrapper:hover, input:focus {
        border: 1px solid #7fb92821;
    }
    .post {
        text-align: start;
        background: #FFFFFF;
        box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
        backdrop-filter: blur(32px);
        border-radius: 15px;
        padding: 16px;
        max-width: 790px;
        margin: 20px 0;
    }
    .select {
        overflow: hidden;
    }
    .top, .bottom {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        p {
            font-weight: bold;
            margin-left: 10px;
            margin-bottom: 0;
        }
        .time {
            margin-left: 3%;
            font-size: 14px;
            opacity: 0.4;
        }
    }
    .bottom {
        margin-top: 10px;
        border-top: 2px solid rgba(128, 128, 128, 0.158);
        padding-top: 10px;
        padding-bottom: 20px;

        .submit-btn {
            margin-left: auto;
        }
    }
    .tags-row {
        padding-left: 10px;
        .tag {
            cursor: pointer;
            font-weight: bold;
            background-color: #CECECE;
            border-radius: 10px;
            color: white;
            margin: 5px;
            width: max-content;
        }
    }
    .thumbnail {
        background-color: grey;
        cursor: pointer;
        border-radius: 10px;
        width: 100%;
        min-height: 300px;
        background-size: cover;
        background-position: center;
    }
    .content {
        max-width: 100%;
        word-wrap: break-word;
        margin: 10px 5px;
        margin-bottom: 20px;
    }
    .actions-row {
        display: flex;
        .comments, .views {
            opacity: 0.5;
        }
        span {
            font-weight: bold;
            margin-left: 4px;
            color: var(--itpark)
        }
    }
    .actions-row > * {
        cursor: pointer;
        margin: 10px;
    }
    @media (max-width: 767px) {
        .post {
            margin-top: 2px !important;
            border-radius: 0 !important;
        }
    }
</style>