<template>
    <company v-if="$store.state.userHasCompany" :id="$store.state.usersCompany.id" />
    <user v-else-if="$store.state.userInfo && $store.state.userInfo.id" :id="$store.state.userInfo.id" />
</template>

<script>
import User from './User_id.vue'
import Company from '../company/Company_id.vue'

export default {
    components: { User, Company },
}
</script>

<style>

</style>