<template>
  <div class="home">
    <left-sidebar :showLogout="id == $store.state.userInfo.id" />
    <div class="main">
      <userHeader
        showChangeBtn="true"
        :mainInfo="mainInfo"
        :company="false"
        @updateHeader="getInfo"
      />
      <div class="row m-0 tabs pl-1" v-if="id == $store.state.userInfo.id">
        <button :class="[tab == 'posts' ? 'main' : '']" @click="tab = 'posts'">
          {{ t("userPosts") }}
        </button>
        <button
          :class="[tab == 'moderating' ? 'main' : '']"
          @click="tab = 'moderating'"
        >
          {{ t("moderating") }}
        </button>
        <button
          :class="[tab == 'create' ? 'main' : '']"
          @click="tab = 'create'"
        >
          {{ t("create") }}
        </button>
      </div>
      <template v-if="tab != 'create'">
        <post
          v-for="(post, index) in news"
          :key="`post ${index}`"
          :postInfo="post"
          class="mx-auto"
        />
        <infinite-loading @infinite="infiniteHandler">
          <div slot="spinner">
            <b-spinner class="m-4" variant="success"></b-spinner>
          </div>
          <div slot="no-more" class="m-4 fw-bold">
            {{ t("noMorePosts") }} <b-icon-emoji-smile-upside-down />
          </div>
          <div slot="no-results" class="m-4 fw-bold">
            {{ t("postsNotFound") }} <b-icon-emoji-neutral />
          </div>
        </infinite-loading>
      </template>
      <template v-else>
        <create-post @submit="tab = 'moderating'" />
      </template>
    </div>
    <right-sidebar />
  </div>
</template>

<script>
import Post from "../../components/Post.vue";
import UserHeader from "../../components/UserHeader.vue";
import InfiniteLoading from "vue-infinite-loading";
import LeftSidebar from "../../components/LeftSidebar.vue";
import RightSidebar from "../../components/RightSidebar.vue";
import CreatePost from "../news/Create.vue";

export default {
  name: "User",
  metaInfo() {
    return {
      title: this.mainInfo.name,
      meta: [
        { name: "description", content: this.t("mainPageDescription") },
        { property: "og:title", content: this.t("mainPageDescription") },
        { property: "og:site_name", content: "techvibe" },
        { property: "og:image", content: this.mainInfo.avatar },
        { property: "og:type", content: "website" },
      ],
      htmlAttrs: {
        lang: this.currLang,
      },
    };
  },
  data() {
    return {
      mainInfo: {},
      news: [],
      page: 0,
      tab: "posts",
      $state: null,
    };
  },
  mounted() {
    const id = this.id ?? this.$route.params.id;
    if (id == undefined) {
      this.$router.push("/");
    } else {
      this.id = id;
      if (this.$route?.query?.tab == "moderating") this.tab = "moderating";

      this.getInfo();
    }
  },
  methods: {
    async infiniteHandler($state) {
      this.$state = $state;
      this.handleTab();
    },
    async getInfo() {
      try {
        this.mainInfo = await this.$store.dispatch("getUserInfo", this.id);
        document.title = this.mainInfo.name;
      } catch (e) {
        this.$router.push("/");
      }
    },
    async getNews() {
      const news = await this.$store.dispatch("getUserNews", {
        page: this.page,
        id: this.id,
      });
      if (news.length) {
        this.news.push(...news);
        this.$state.loaded();
      } else {
        this.$state.complete();
      }
    },
    async getDraftNews() {
      const resp = await this.axios(
        `news/moderate/user/current?page=${this.page}`
      );
      const news = resp?.data?.data || [];
      if (news.length) {
        this.news.push(...news);
        this.$state.loaded();
      } else {
        this.$state.complete();
      }
    },
    handleTab() {
      this.page++;
      if (this.tab == "moderating") {
        this.getDraftNews();
      } else if (this.tab == "posts") {
        this.getNews();
      }
    },
  },
  watch: {
    tab() {
      this.news = [];
      this.page = 0;
      this.$state?.reset();
      this.handleTab();
    },
  },
  props: ["id"],
  components: {
    UserHeader,
    Post,
    InfiniteLoading,
    LeftSidebar,
    RightSidebar,
    CreatePost,
  },
};
</script>

<style scoped>
div.main {
  padding: 0 !important;
  margin-top: 20px;
  padding-bottom: 100px;
}
.tabs {
  padding-top: 25px;
  padding-bottom: 3px;
}
.tabs > button {
  font-weight: 600;
  margin-right: 10px;
  border: none !important;
  border-radius: 5px !important;
  max-width: max-content;
  padding: 0 10px !important;
  margin-top: 0;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .main {
    margin-top: 2px !important;
  }
}
</style>