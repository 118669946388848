<template>
  <div class="home">
    <left-sidebar :showLogout="id == $store.state.usersCompany.id" />
    <div class="main">
      <userHeader
        showChangeBtn="true"
        :socials="socials"
        :mainInfo="mainInfo"
        :company="true"
      />
      <post
        v-for="(post, index) in news"
        :key="`post ${index}`"
        :postInfo="post"
        class="mx-auto"
      />
      <infinite-loading @infinite="infiniteHandler">
        <div slot="spinner">
          <b-spinner class="m-4" variant="success"></b-spinner>
        </div>
        <div slot="no-more" class="m-4 fw-bold">
          {{ t("noMorePosts") }} <b-icon-emoji-smile-upside-down />
        </div>
        <div slot="no-results" class="m-4 fw-bold">
          {{ t("postsNotFound") }} <b-icon-emoji-neutral />
        </div>
      </infinite-loading>
    </div>
    <right-sidebar />
  </div>
</template>

<script>
import Post from "../../components/Post.vue";
import UserHeader from "../../components/UserHeader.vue";
import InfiniteLoading from "vue-infinite-loading";
import LeftSidebar from "../../components/LeftSidebar.vue";
import RightSidebar from "../../components/RightSidebar.vue";

export default {
  name: "Company",
  metaInfo() {
    return {
      title: this.mainInfo.name,
      meta: [
        { name: "description", content: this.mainInfo.description },
        { property: "og:title", content: this.mainInfo.description },
        { property: "og:site_name", content: "Techvibe" },
        { property: "og:image", content: this.mainInfo.avatar },
        { property: "og:type", content: "website" },
      ],
      htmlAttrs: {
        lang: this.currLang,
      },
    };
  },
  data() {
    return {
      mainInfo: {},
      news: [],
      socials: {},
      page: 0,
    };
  },
  async mounted() {
    window.scroll(0, 0);
    const id = this.id ?? this.$route.params.id;
    if (id == undefined) {
      this.$router.push("/");
    } else {
      this.id = id;
      await this.getInfo();
    }
  },
  methods: {
    async infiniteHandler(loaderState) {
      this.page++;
      await this.getNews(loaderState);
    },
    async getInfo() {
      try {
        this.mainInfo = await this.$store.dispatch("getCompanyInfo", this.id);
        const socials = {};
        const socialsResp = await this.axios.get(
          "public/company/social/" + this.id
        );
        for (let social of socialsResp?.data?.data) {
          socials[social.type] = social.content;
        }
        this.socials = socials;
        document.title = this.mainInfo.name + " ☑";
      } catch (e) {
        this.$router.push("/");
      }
    },
    async getNews(loaderState) {
      let resp = null;
      if (this.$store.state.loggedIn) {
        resp = await this.axios({
          method: "GET",
          url: `company/private/news/${this.id}`,
          params: {
            page: this.page,
            locale: this.currLang,
          },
        });
      } else {
        resp = await this.axios({
          method: "GET",
          url: `company/public/news/${this.id}`,
          params: {
            page: this.page,
            locale: this.currLang,
          },
        });
      }
      const news = resp?.data?.data || [];
      if (news.length) {
        this.news.push(...news);
        loaderState.loaded();
      } else {
        loaderState.complete();
      }
    },
  },
  props: ["id"],
  components: {
    UserHeader,
    Post,
    InfiniteLoading,
    LeftSidebar,
    RightSidebar,
  },
};
</script>

<style scoped>
.main {
  padding: 0 !important;
  margin-top: 20px;
  padding-bottom: 100px;
}
@media (max-width: 767px) {
  .main {
    margin-top: 2px !important;
  }
}
</style>